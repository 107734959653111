@import '@folio/stripes-components/lib/variables.css';

:root {
  --main-nav-min-height-desktop: 56px;
  --main-nav-min-height-mobile: 48px;
  --main-nav-border-bottom-color: rgba(0 0 0 / 90%);
  --main-nav-background-color: rgba(0 0 0 / 80%);
  --main-nav-text-color: #fff;
}

.navRoot {
  background-color: var(--main-nav-background-color);
  border-bottom: 1px solid var(--main-nav-border-bottom-color);
  color: var(--main-nav-text-color);
  display: flex;
  place-content: stretch space-between;
  align-items: center;
  width: 100%;
  flex: 0 0 auto;
  padding: 0 var(--gutter-static-one-third);
  min-height: var(--main-nav-min-height-desktop);
  z-index: 99999;
  position: relative;

  & li {
    color: var(--color-text);
  }
}

.nowrap {
  white-space: nowrap;
}

.startSection,
.endSection {
  display: flex;
  align-items: center;
}

.endSection {
  flex: 1;
  justify-content: flex-end;
  min-width: 0;
}

@media (--medium-down) {
  .navRoot {
    min-height: var(--main-nav-min-height-mobile);
  }
}
