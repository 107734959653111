@import "@folio/stripes-components/lib/variables.css";

body {
  overflow-y: auto;
}

.wrapper {
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.container {
  width: 100%;
  max-width: 940px;
  min-height: 330px;
  margin: 5vh 2rem 0;
}

.header {
  margin: 0;
  font-weight: var(--text-weight-basis);
  font-size: var(--font-size-small);
}

.form {
  width: 100%;
  outline: 0;
  text-align: center;

  & .passResetInput {
    height: 44px;
    padding: 8px;
    font-size: var(--font-size-medium);
  }

  & .passResetSubmitButton {
    height: 44px;
    max-height: 100%;
    margin-top: 1.5rem;
    font-size: var(--font-size-medium);
  }
}

.formGroup {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.authErrorsWrapper {
  margin-top: 10px;
}



.toggleButtonWrapper {
  display: flex;
  justify-content: center;

  & > button {
    margin: 1.5rem 0 0;
  }
}

.link {
  display: block;
  width: 100%;
  text-align: right;
  font-size: var(--font-size-large);
  font-weight: var(--text-weight-headline-basis);
  margin: 0 0 0.25rem;
}

.passwordStrength {
  text-align: start;
  margin-top: 1rem;
}

@media (--small-up) {
  .header {
    font-size: var(--font-size-medium);
  }

  .container {
    margin: 1vh 2rem 0;
  }
}

@media (--medium-up) {
  .header {
    font-size: var(--font-size-x-large);
  }

  .container {
    min-height: initial;
  }

  .form {
    & .passResetSubmitButton {
      height: 60px;
      font-size: var(--font-size-large);
    }

    & .passResetInput {
      height: 60px;
      padding: 15px;
      font-size: var(--font-size-medium);
    }
  }

  .passwordStrength {
    margin-top: 0;
  }
}

@media (--large-up) {
  .header {
    font-size: var(--font-size-xx-large);
  }

  .toggleButtonWrapper {
    justify-content: left;

    & > button {
      margin: 0 0 1rem 1rem;
    }
  }
}

@media only screen and (width >= 1450px) {
  .container {
    margin: 12vh 2rem 0;
  }
}

@media (height <= 440px) {
  .container {
    min-height: 330px;
  }
}
