@import "@folio/stripes-components/lib/variables.css";

/**
 * Front
 */

.frontWrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1.frontTitle {
  font-size: var(--font-size-xx-large);
  text-align: center;
  padding: 15px;
  line-height: 125%;
}

@media screen and (width <= 900px) {
  h1.frontTitle {
    font-size: var(--font-size-large);
  }
}
