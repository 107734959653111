@import '@folio/stripes-components/lib/variables.css';

.navDivider {
  border-left: 1px solid rgba(255 255 255 / 20%);
  height: 24px;
  margin: 0 5px;
}

@media (--medium-down) {
  .hideMed {
    display: none;
  }
}
