/**
 * AppIcon
 */

@import "@folio/stripes-components/lib/variables";

/**
 * Icon
 */

.appIcon {
  display: inline-flex;
  align-items: center;
}

/**
 * Label
 */

.label {
  margin: 0 0 0 0.35em;
}

[dir="rtl"] .label {
  margin: 0 0.35em 0 0;
}

/**
 * Icon
 */
.icon {
  display: inline-block;
  flex-shrink: 0;
  line-height: 1;
  position: relative;
  opacity: 1;
  transition: none;
  border-radius: 25%;
  background-color: var(--color-icon) !important;
  color: var(--bg);

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    box-shadow: inset 0 0 0 1px rgba(0 0 0 / 20%);
    border-radius: 25%;
  }

  & img {
    width: 100%;
    border-radius: 25%;
    height: auto;
    vertical-align: top;
  }
}

/**
 * Icon alignment
 */
.icon-alignment-center {
  align-items: center;
}

.icon-alignment-baseline {
  align-items: baseline;
}

/**
 * Sizes
 */
.appIcon.large {
  min-width: 48px;
}

.large .icon {
  height: 48px;
  min-width: 48px;
  width: 48px;
}

.appIcon.medium {
  min-width: 24px;
}

.medium .icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.appIcon.small {
  min-width: 14px;
}

.small .icon {
  width: 14px;
  min-width: 14px;
  height: 14px;
}
