.moduleContainer {
  z-index: 9;
  position: relative;
  outline: none;
  flex: 1 1 auto;
  overflow-y: auto;
  height: 100%;

  @media print {
    overflow-y: visible;
  }
}
