/**
 * AppList -> ResizeContainer
 */

.resizeContainerWrapper {
  flex: 1;
  display: flex;
  min-width: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out 0.07s;

  &.ready {
    opacity: 1;
  }
}

.resizeContainerInner {
  overflow: hidden;
  flex: 1;
}
