@import '@folio/stripes-components/lib/variables.css';

:root {
  --main-nav-button-font-size: 1rem;
  --main-nav-button-label-spacing: 6px;
  --main-nav-button-padding: 0.53rem;
  --main-nav-button-min-height: var(--control-min-size-touch);
  --main-nav-button-color: #fff;
}

.navButton,
.inner {
  display: flex;
  align-items: center;
  position: relative;
  flex-shrink: 0;
}

.inner {
  position: relative;
  padding: var(--main-nav-button-padding) 0;
}

.navButton .inner::before {
  left: calc(var(--main-nav-button-padding) * -1);
  right: calc(var(--main-nav-button-padding) * -1);
}

.navButton {
  composes: interactionStylesControl from "~@folio/stripes-components/lib/sharedStyles/interactionStyles.css";  /* stylelint-disable-line */
  padding: 0 var(--gutter-static-one-third);
  min-height: var(--main-nav-button-min-height);
  font-size: var(--main-nav-button-font-size);
  outline: 0;
  opacity: 0.75;

  &::-moz-focus-inner {
    border: 0;
  }

  &:hover,
  &:focus {
    opacity: 1;
  }

  &.selected {
    opacity: 1;

    & .inner::before {
      background-color: var(--primary);
    }
  }
}

.isInteractive {
  composes: interactionStyles from "~@folio/stripes-components/lib/sharedStyles/interactionStyles.css";  /* stylelint-disable-line */
}

/* To make sure we overwrite default link colors */
a.navButton,
button.navButton {
  color: var(--main-nav-button-color);
}

.icon {
  display: flex;

  & svg {
    fill: currentcolor;
  }
}

.icon,
.label {
  position: relative;
  z-index: 4;
}

.label {
  font-weight: var(--text-weight-bold);
  display: flex;
  align-items: center;
}

[dir="ltr"] .label {
  margin-left: var(--main-nav-button-label-spacing);
}

[dir="rtl"] .label {
  margin-right: var(--main-nav-button-label-spacing);
}

/**
 * Badge
 */
.navButton .badge {
  font-size: var(--font-size-x-small);
  position: absolute;
  top: 0;
  right: -1px;
  z-index: 5;
}

/**
 * AppIcon
 */
.appIcon > span::after {
  box-shadow: inset 0 0 0 1px rgba(255 255 255 / 10%);
}

/**
 * Caret icon
 */
.caretIcon {
  font-size: 1.1rem;
  margin: 0.3rem 0 0 0.35rem;
}

[dir="rtl"] .caretIcon {
  margin: 0.3rem 0.35rem 0 0;
}
