@import "@folio/stripes-components/lib/variables.css";

.logo {
  max-width: 460px;
  margin: 0 auto 10px;
  text-align: center;
  justify-content: center;

  & img {
    max-width: 460px;
    max-height: 300px;
  }
}

@media (height <= 440px) {
  .logo {
    margin-top: 0;
  }
}

@media (--medium-down) {
  .logo {
    margin-top: 0;

    & img {
      max-width: 300px;
    }
  }
}

@media (--small) {
  .logo {
    margin-top: 0;

    & img {
      max-width: 240px;
    }
  }
}

@media only screen and (width >= 1300px) {
  .logo {
    margin-bottom: 20px;
  }
}
