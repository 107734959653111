/**
 * SkipLink
 */

a.skipLink {
  position: absolute;
  top: -100px;
  transition: top 0.15s linear;

  &:focus {
    position: relative;
    top: 0;
  }
}

.skipLink__icon {
  height: 22px;
  width: 22px;
}
