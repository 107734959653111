@import "@folio/stripes-components/lib/variables.css";

.wrapper {
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 940px;
  min-height: 330px;
}

.form {
  width: 100%;
  outline: 0;
  text-align: center;

  & .loginInput {
    height: 44px;
    padding: 8px;
    font-size: var(--font-size-medium);
  }

  & .loginSubmitButton {
    height: 44px;
    max-height: 100%;
    margin-top: 0.5rem;
    font-size: var(--font-size-medium);
  }
}

.formGroup {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.linksWrapper,
.authErrorsWrapper {
  margin-top: 1rem;
}

.link {
  display: block;
  width: 100%;
  font-size: var(--font-size-large);
  font-weight: var(--text-weight-headline-basis);
  margin: 0;
}

@media (--medium-up) {
  .container {
    min-height: initial;
  }

  .form {
    & .loginSubmitButton {
      height: 60px;
      font-size: var(--font-size-large);
    }

    & .loginInput {
      height: 60px;
      padding: 15px;
      font-size: var(--font-size-medium);
    }
  }

}

@media (--large-up) {
  .header {
    font-size: var(--font-size-xx-large);
  }

  .toggleButtonWrapper {
    justify-content: left;

    & > button {
      margin: 0 0 1rem 1rem;
    }
  }
}

@media (height <= 440px) {
  .container {
    min-height: 330px;
  }
}
