/**
 * Styling for login and sign up
 */
@import "@folio/stripes-components/lib/variables.css";

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.centered {
  position: relative;
  max-width: 500px;
  width: 100%;
  padding: 30px 15px;
}

.form {
  outline: 0;
  text-align: center;

  & .forgotUserInput {
    height: 60px;
    font-size: var(--font-size-medium);
    padding: 15px;
  }

  & .forgotUserSubmitButton {
    height: 60px;
    max-height: 100%;
    font-size: var(--font-size-large);
  }
}

.formGroup {
  margin-bottom: 10px;
}

.form .formGroup:last-child {
  margin-bottom: 0;
}

.authErrorsWrapper {
  margin-top: 10px;
}

@media (height <= 440px) {
  .centered {
    min-height: 330px;
  }
}

@media (width <= 640px) {
  .centered {
    min-height: 330px;
  }

  .form {
    & .forgotUserSubmitButton {
      height: 44px;
      font-size: var(--font-size-medium);
    }

    & .forgotUserInput {
      height: 44px;
      font-size: var(--font-size-medium);
      padding: 8px;
    }
  }
}
