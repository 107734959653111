/**
 * My profile
 */

@import "@folio/stripes-components/lib/variables.css";

/* Logged in as */
.header {
  color: var(--color-text-p2);
}

/* Divider */
.divider {
  border: 1px solid rgba(0 0 0 / 10%);
  border-width: 0 0 1px;
}

/* Avatar */
.button .avatar {
  border: 0;
  box-shadow: inset 0 0 0 1px rgba(255 255 255 / 10%);
  background-color: rgba(255 255 255 / 40%);
}

.button__label {
  margin: 0 0.35rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 15rem;

  & span {
    width: 100%;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
