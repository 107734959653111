@import "@folio/stripes-components/lib/variables.css";

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.centered {
  position: relative;
  max-width: 500px;
  padding: 30px 15px;
  text-align: center;
}

.submitButton {
  height: 60px;
  max-height: 100%;
  margin-top: 1rem;
  font-size: var(--font-size-large);
}
