/**
 * System Skeleton
 */

@import "@folio/stripes-components/lib/variables";

:root {
  --skeleton-bg: rgba(255 255 255 / 8%);
}

.skeleton {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.skeletonBar {
  height: 16px;
  width: 100px;
  background-color: var(--skeleton-bg);
  flex-shrink: 0;

  &.marginBottom {
    margin-bottom: 15px;
  }

  &.marginLeft {
    margin-left: 30px;
  }
}

.skeletonAppIcon {
  height: 24px;
  width: 24px;
  margin: 0 7px;
  border-radius: 6px;
  background-color: var(--skeleton-bg);
}

.skeletonCircle {
  height: 24px;
  width: 24px;
  margin: 0 7px;
  border-radius: 999px;
  background-color: var(--skeleton-bg);
}

.skeletonDivider {
  width: 1px;
  background-color: var(--color-border-p2);
  height: 24px;
  margin: 0 7px;
}

/**
 * Header
 */
.skeletonHeader,
.skeletonHeaderStart,
.skeletonHeaderEnd {
  display: flex;
  align-items: center;
}

.skeletonHeader {
  padding: 0 8px;
  height: 56px;
  background-color: rgba(0 0 0 / 80%);
  border-bottom: 1px solid rgba(0 0 0 / 90%);
  justify-content: space-between;
}

/**
 * Content
 */
.content {
  flex: 1;
  display: flex;
}

@media (--medium-down) {
  .hideOnSmallScreens {
    display: none;
  }

  .skeletonHeader {
    height: 48px;
  }
}
