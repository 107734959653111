.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.centered {
  position: relative;
  max-width: 500px;
  width: 100%;
  padding: 30px 15px;
  text-align: center;
}
