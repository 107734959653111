/**
 * App Switcher
 */

@import '@folio/stripes-components/lib/variables.css';

:root {
  --padding-for-header: 66px;
}

.appList {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  min-width: 0;
}

/**
 * Nav dropdown toggle
 **/

.dropdownToggleLabel {
  display: none;
}

.dropdownToggleIcon {
  height: var(--control-min-size-desktop);
  width: var(--control-min-size-desktop);
}

/**
 * Nav List
 */
.navItemsList {
  list-style: none;
  margin: 0;
  padding: 0 var(--gutter-static-two-thirds);
  display: flex;
  justify-content: flex-end;
}

/**
 * Nav Dropdown
 */

.navListDropdownWrap {
  margin: 0 var(--gutter-static-two-thirds);
}

.navListDropdownWrap,
.navListDropdown {
  display: block;
}

.navListDropdown {
  z-index: 99999;
}

.navListDropdown > div {
  overflow-y: auto;
}

.dropdownBody {
  width: 100%;
  outline: 0;
  padding: var(--gutter-static) 0;
}

.dropdownList {
  margin: 0;
  max-height: calc(100vh - var(--padding-for-header));
}

.dropdownListItem {
  display: flex;
  align-items: center;
}

.dropdownListItemLabel {
  padding: 0 var(--gutter-static-one-third);
  min-width: 200px;
  font-weight: var(--text-weight-bold);
}

[dir="rtl"] .dropdownListItemLabel {
  text-align: right;
}

/**
 * Nav Item
**/

.navItem {
  display: flex;
  flex-shrink: 0;
  transition: opacity 0.1s, visibility 0.1s, width 0.07s ease-in-out;
  padding: 0 var(--gutter-static-one-third);

  &.hidden {
    width: 0 !important;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    padding: 0;
  }
}

/**
 * Responsive
 */

/* A bit of a hack to force tether to 100% width on mobile.. */
@media (--small) {
  .navListDropdown,
  .navListDropdown > div {
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
  }
}

@media (--medium-up) {
  .dropdownToggleLabel {
    display: flex;
  }
}
